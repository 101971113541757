import React, { FC } from 'react'

import { useTransition } from '@react-spring/web'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import {
  BANNER_ANIMATION_DURATION,
  installMobileAppBannerCookie,
} from './InstallAppBanner.constants'
import { InstallMobileAppBanner } from './InstallMobileAppBanner'
import { executeAppsFlyerSmartScript } from './scripts/appsFlyerSmartScript'

if (process.env.browser) {
  executeAppsFlyerSmartScript()
}

export const InstallMobileAppBannerSwitch = () => {
  const { cookies, javaScriptEnabled } = useShallowEqualSelector(
    ({ systemReducer: { cookies, javaScriptEnabled } }) => ({
      cookies,
      javaScriptEnabled,
    })
  )

  if (!cookies[installMobileAppBannerCookie]) {
    if (!javaScriptEnabled) {
      return <BannerDisabledJs />
    } else {
      return <BannerNormal />
    }
  }

  return null
}

const BannerDisabledJs: FC = () => {
  return <InstallMobileAppBanner />
}

const BannerNormal: FC = () => {
  const { cookies } = useShallowEqualSelector(
    ({ systemReducer: { cookies } }) => ({
      cookies,
    })
  )

  const transitions = useTransition(!cookies[installMobileAppBannerCookie], {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: -10 },
    config: { duration: BANNER_ANIMATION_DURATION },
  })

  return transitions(
    (springs, show) => show && <InstallMobileAppBanner springs={springs} />
  )
}

export default InstallMobileAppBannerSwitch
