import React, { FC, MouseEventHandler, useCallback, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { sendStatisticsAction } from 'actions/analytics/sendStatisticsAction'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { updateCookieMobileAppBannerAction } from 'actions/system/systemAction'
import { isLoveMailru } from 'common/constants'
import { Colors } from 'common-constants/colors'
import {
  pathnameFormName,
  setCookieInstallMobileAppBannerPath,
} from 'common-constants/formAction'
import { CloseSvg } from 'components/designSystem/svgr/CloseSvg'
import { callYandexMetrika } from 'components/metric/callYandexMetrika'
import { MetricEventSend } from 'components/metric/metric.types'
import { fullStopPropagation } from 'functions/fullStopPropagation'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  ThirdPartyPlace,
  useThirdPartyUrl,
} from 'hooks/temrorary/useThirdPartyUrl'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { bannerReducer } from 'reducers/banner/bannerReducer'

import { useMobileAppUrl } from './hooks/useAppUrl'
import { useCreateBannerInfo } from './hooks/useCreateBannerInfo'
import { installMobileAppBannerCookie } from './InstallAppBanner.constants'
import {
  Block,
  CloseButton,
  InstallAppBannerContainer,
  DownloadButton,
  Logo,
  StyledStarsRatingSvg,
  Subtitle,
  Title,
} from './InstallAppBanner.styles'
import { installMobileAppBannerLayerPath } from './InstallMobileAppBannerLayer.paths'
import { setCookieValue } from '../../../../client/functions/setCookie'

export const InstallMobileAppBanner: FC<{
  springs?: unknown
}> = ({ springs }) => {
  useReducersInsert({ bannerReducer })
  const dispatch = useDispatch()

  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  const loveMailRu = isLoveMailru(partnerId)
  const url = useMobileAppUrl()
  const thirdPartyUrl = useThirdPartyUrl(ThirdPartyPlace.downloadAppBanner)

  const { alias, logoSrc, title, containerStyles } = useCreateBannerInfo()

  const handleCloseClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    setCookieValue(
      installMobileAppBannerCookie,
      true,
      /**
       * Кука должна ставится на один день:
       * https://youtrack.mamba.ru/issue/M-9453#focus=Comments-4-58177.0-0
       */
      1
    )
    dispatch(updateCookieMobileAppBannerAction())
    /** Нужно обновить шелл после апдейта куки */
    dispatch(updateShellAction())
    fullStopPropagation(event)
  }

  const handleContainerClick: MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      async (event) => {
        event.preventDefault()
        callYandexMetrika(MetricEventSend.WebToAppBannerClick)
        const result = await dispatch(
          sendStatisticsAction('click', 'web_to_app', alias)
        )

        if (result.ok) {
          window.open(url, '_self')
        }
      },
      [alias, dispatch, url]
    )

  useEffect(() => {
    if (url) {
      dispatch(sendStatisticsAction('view', 'web_to_app', alias))
    }
  }, [alias, dispatch, url])

  const content = (
    <BannerContent
      onCloseClick={handleCloseClick}
      logoSrc={logoSrc}
      title={title}
      isLoveMailRu={loveMailRu}
      data-name="close-install-banner-action"
    />
  )

  if (thirdPartyUrl) {
    return (
      <InstallAppBannerContainer
        id="install-mobile-app"
        $styles={containerStyles}
        style={springs!}
        target="_self"
        href={thirdPartyUrl}
      >
        {content}
      </InstallAppBannerContainer>
    )
  } else if (process.env.browser) {
    return (
      <InstallAppBannerContainer
        id="install-mobile-app"
        $styles={containerStyles}
        style={springs!}
        onClick={handleContainerClick}
        target="_self"
        href={url}
      >
        {content}
      </InstallAppBannerContainer>
    )
  } else {
    return (
      <InstallAppBannerContainer
        id="install-mobile-app"
        $styles={containerStyles}
        style={springs!}
        onClick={handleContainerClick}
        target="_blank"
        href={mergeAllUrls(installMobileAppBannerLayerPath)}
      >
        {content}
      </InstallAppBannerContainer>
    )
  }
}

const BannerContent: FC<{
  onCloseClick: MouseEventHandler<HTMLButtonElement>
  logoSrc: string
  title: string
  isLoveMailRu: boolean
}> = ({ onCloseClick, logoSrc, title, isLoveMailRu }) => {
  return (
    <>
      <CloseBanner onCloseClick={onCloseClick} />
      <Logo src={logoSrc} />

      <Block>
        <Title>{title}</Title>

        {!isLoveMailRu && (
          <Subtitle>
            <FormattedMessage
              id="app.dating.nearby"
              defaultMessage="Знакомства рядом"
            />
          </Subtitle>
        )}

        <StyledStarsRatingSvg />
      </Block>

      <DownloadButton>
        <FormattedMessage id="app.install" defaultMessage="Установить" />
      </DownloadButton>
    </>
  )
}

const CloseBanner: FC<{
  onCloseClick: MouseEventHandler<HTMLButtonElement>
}> = ({ onCloseClick }) => {
  const { pathname } = useLocation()

  const { javaScriptEnabled } = useShallowEqualSelector(
    ({ systemReducer: { javaScriptEnabled } }) => ({
      javaScriptEnabled,
    })
  )

  const close = <CloseSvg innerFill={Colors.white} width={16} height={16} />

  if (!javaScriptEnabled) {
    return (
      <form
        id="closeInstallMobileAppBanner"
        action={setCookieInstallMobileAppBannerPath}
        method="POST"
      >
        <input type="hidden" name={pathnameFormName} value={pathname} />
        <CloseButton type="submit" data-name="close-install-banner-action">
          {close}
        </CloseButton>
      </form>
    )
  }

  return (
    <CloseButton onClick={onCloseClick} data-name="close-install-banner-action">
      {close}
    </CloseButton>
  )
}
